<template>
  <Header :page="this.$route.name" />
  <section
    :style="{
      'background-image':
        'url(' + require('@/assets/svg/background-header.svg') + ')',
    }"
    class="relative flex items-center justify-center -mt-24 overflow-hidden h-700 lg:bg-cover"
  >
    <div class="mx-3">
      <h1 class="text-3xl font-semibold text-center md:text-5xl">
        Every Trybe deserves <br class="hidden lg:block" />
        a great meal
      </h1>
      <div class="z-10 flex items-center justify-center lg:mt-4">
        <div
          class="flex items-center justify-around w-full pl-2 mt-4 space-x-2 rounded-full bg-white-important h-14 md:w-10/12"
        >
          <div class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <div class="w-1 h-6 bg-gray-200 rounded-full"></div>
          <vue-google-autocomplete ref="address" id="map" 
          placeholder="Enter Delivery Address" class="w-full rounded-r-full bg-white-important focus:outline-none"       
          :types="['establishment']"
          country="ng"
          v-model="deliveryAddress" 
          @placechanged="getAddressData"
          @keyup.enter="SortAddress">
          </vue-google-autocomplete>
        </div>
        <div class="z-10 mt-4 -ml-8 h-14">
          <button
            class="flex items-center justify-center h-full border-4 border-white rounded-full shadow-md bg-brand w-14"
            @click="SortAddress"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <p v-if="getError" class="my-2 text-xs text-center text-red-500">
        {{ getError }}
      </p>
      <div class="flex justify-center mt-4 space-x-2 text-xs text-gray-500">
        <div class="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <p class="font-semibold">
          Currently Available to customers in Ikeja and Magodo axis
        </p>
      </div>
    </div>
    <div class="absolute left-0 w-screen lg:-bottom-36 -bottom-10">
      <div class="w-screen bg-white h-custom"></div>
    </div>
  </section>
  <section class="container px-8 mx-auto mb-28">
    <div class="flex justify-center">
      <div class="">
        <img
          src="@/assets/svg/scheduled-order-1.svg"
          class="mx-auto h-80 w-80"
          alt="Noshtribe"
        />
        <div class="-mt-8">
          <h1 class="text-3xl font-semibold text-center md:text-5xl">
            Schedule an order
          </h1>
          <div class="container px-3 mx-auto md:px-0 lg:px-80">
            <p class="my-4 text-center">
              Most people want to plan ahead what they eat, when they eat and
              where they eat from. This takes the guess work out of the food
              ordering experience. Our order scheduling process allows you to
              find your favorite restaurant, select a preferred time-belt
              (Breakfast, Lunch, Dinner etc) as well as the expected delivery
              date.
            </p>
          </div>
          <div class="flex justify-center">
            <button
              @click="getStarted"
              class="px-6 py-3 mx-auto text-sm font-semibold rounded-md bg-brand"
            >
              Get started
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="container px-8 mx-auto my-28">
    <div class="flex justify-center">
      <div class="">
        <img
          src="@/assets/svg/group-buy.svg"
          class="mx-auto h-80 w-80"
          alt="Noshtribe"
        />
        <div class="-mt-8">
          <div class="flex justify-center space-x-3">
            <h1 class="text-3xl font-semibold text-center md:text-5xl">
              Group Buy
            </h1>
            <div>
              <span class="p-2 text-xs text-gray-100 bg-red-500 rounded"
                >Coming soon</span
              >
            </div>
          </div>
          <div class="container px-3 mx-auto lg:px-80">
            <p class="my-4 text-center">
              We reward Trybe Members for every meal ordered on Noshtrybe. By
              ordering meals as a member of a Trybe and inviting other members
              of your Trybe to book same day, Noshtrybe rewards every Trybe
              member with a group discount credited to the wallet based on meals
              delivered by 12midnight everyday
            </p>
          </div>
          <div class="flex justify-center">
            <button
              @click="getStarted"
              class="px-6 py-3 mx-auto text-sm font-semibold rounded-md bg-brand"
            >
              Get started
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- 3steps -->
  <section class="py-8 lg:my-28 my-14 bg-brand bg-opacity-10 lg:py-4">
    <div class="mx-3 md:my-32 md:p-3 md:mx-16">
      <div class="flex justify-center">
        <div>
          <h1 class="text-3xl font-semibold text-center md:text-5xl">
            3 steps to happiness
          </h1>
          <p class="px-8 mt-3 text-sm text-center lg:px-0">
            Our team ensures your food gets delivered in 3 simple steps
          </p>
        </div>
      </div>
      <div class="grid gap-4 mt-4 md:grid-cols-3">
        <div class="my-6 md:p-4 lg:my-0">
          <div class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-14 w-14 text-brand"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
          <div>
            <h1 class="mt-4 text-base font-semibold text-center">
              Find a restaurant
            </h1>
            <p class="px-8 mt-2 text-center lg:px-0">
              We make finding restaurant close to you easy. Enter your delivery
              address to narrow your search.
            </p>
          </div>
        </div>
        <div class="my-6 md:p-4 lg:my-0">
          <div class="flex justify-center">
            <img src="@/assets/img/meal.png" class="h-14 w-14" alt="Meals" />
          </div>
          <div>
            <h1 class="mt-4 text-base font-semibold text-center">
              Select your meal
            </h1>
            <p class="px-8 mt-4 text-center lg:px-0">
              Choose your delivery date, time-belt (eg breakfast, lunch,dinner
              etc) and add meals to your cart
            </p>
          </div>
        </div>
        <div class="my-6 md:p-4 lg:my-0">
          <div class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-14 w-14 text-brand"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
              <path
                fill-rule="evenodd"
                d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div>
            <h1 class="mt-4 text-base font-semibold text-center">
              Make payment and wait for the magic
            </h1>
            <p class="px-8 mt-4 text-center lg:px-0">
              Pay for your order and the rest is the magic that delivers to your
              doorstep.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- It all here Section -->
  <section class="py-16 mx-6 lg:my-28 my-14 lg:mt-8 lg:py-2 md:mx-16">
    <div class="lg:grid lg:grid-cols-2">
      <div class="flex items-center">
        <div class="lg:pl-16">
          <h1 class="text-3xl font-semibold md:text-5xl">
            Its all here.<br />in one App.
          </h1>
          <p class="mt-4">
            Enjoy the Noshtrybe experience across all
            <br class="hidden lg:block" />
            your device (Android and iOS coming soon).
          </p>
          <button
            @click="getStarted"
            class="px-6 py-3 mt-4 text-sm font-semibold rounded-md bg-brand"
          >
            Get started
          </button>
        </div>
      </div>
      <div class="flex justify-center mt-16 lg:justify-start lg:mt-0">
        <div
          class="rounded-full h-350 w-300 lg:h-500 lg:w-500 p-14 bg-gradient-to-b from-brand"
        >
          <img
            src="@/assets/svg/mobile.svg"
            loading="lazy"
            class="object-fill"
            alt="taste the meal"
          />
        </div>
      </div>
    </div>
  </section>
  <section
    class="flex flex-col-reverse mx-6 md:grid md:grid-cols-2 gap-14 lg:my-28 my-14"
  >
    <div class="flex justify-center mt-8 lg:justify-end lg:mt-0">
      <div
        class="flex items-center justify-center p-12 rounded-full h-350 w-300 lg:h-500 lg:w-500 bg-gradient-to-b from-brand"
      >
        <img
          src="@/assets/img/home-food-2.png"
          loading="lazy"
          class="object-fit"
          alt="taste the meal"
        />
      </div>
    </div>
    <div class="flex items-center justify-start mt-8 lg:mt-0">
      <div class="text-left">
        <h1 class="text-3xl font-semibold md:text-5xl">
          Living well begins with eating well.
        </h1>
        <p class="mt-4">
          Because your health matter to us, we ensure only restaurants with
          experience and proven track records in delivery of high quality meals,
          are curated on the platform
        </p>
      </div>
    </div>
  </section>
  <Footer />

  <Modal v-if="showPopup" :show="true" width="w-768">
    <div class="flex justify-end">
      <button @click="toggleshowPopup">
        <svg
          class="w-8 h-8 text-black"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
    <div class="grid gap-4 p-4 lg:grid-cols-2">
      <div class="p-4 bg-gray-100 rounded-md shadow-md">
        <div class="grid grid-rows-4 gap-4">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-16 h-16 text-deep-brand"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
              />
            </svg>
          </div>
          <h1 class="text-2xl font-semibold">
            I want to order <br />
            FOOD
          </h1>
          <div class="text-sm">
            <p>
              I love great meals and will order with friends and loved ones for
              exciting shared experiences
            </p>
          </div>
          <div class="flex items-end">
            <button
              @click="pushLogin"
              class="px-4 py-2 text-sm font-semibold rounded bg-brand"
            >
              For Customers
            </button>
          </div>
        </div>
      </div>
      <div class="p-4 bg-gray-100 rounded-md shadow-md">
        <div class="grid grid-rows-4 gap-4">
          <div>
            <img
              src="https://img.icons8.com/color/96/000000/restaurant-.png"
              class="w-16 h-16"
            />
          </div>
          <h1 class="text-2xl font-semibold">
            I own a <br />
            RESTAURANT
          </h1>
          <div class="text-sm">
            <p>
              We operate high quality restaurants that make great meals for
              anyone looking to eat and stay healthy
            </p>
          </div>
          <div class="flex items-end">
            <a
              href="https://vendor.noshtrybe.com"
              class="px-4 py-2 text-sm font-semibold rounded bg-brand"
              >For Vendors</a
            >
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Header from "@/components/Header.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Footer from "@/components/Footer.vue";
import Modal from "@/components/Modal.vue";
export default {
  name: "NewHompage",
  components: {
    Header,
    VueGoogleAutocomplete,
    Footer,
    Modal,
  },
  data() {
    return {
      deliveryAddress: "",
      showPopup: false,
      restaurant: {
        publicURL: process.env.VUE_APP_DEFAULT_STORE,
      },
    };
  },
  methods: {
    SortAddress() {
      this.address = this.address
        ? this.address
        : this.$store.getters.getAddress;
      if (
        this.deliveryAddress &&
        this.deliveryAddress == this.address.address
      ) {
        let geoData = {
          address: this.address.address,
          latitude: this.address.latitude,
          longitude: this.address.longitude,
        };
        if(this.$store.getters.getcountCart){
          this.$store.getters.clearCoutCart()
        }
        this.$store.dispatch("addDeliveryaddress", JSON.stringify(geoData));
        this.$router.push({ name: "Resturants" });
        this.$store.dispatch("errAddress", "");
        this.$store.dispatch("errAddress", "");
      } else {
        this.$store.dispatch(
          "errAddress",
          "Please enter your delivery address"
        );
      }
    },
    getAddressData: function (addressData, placeResultData, id) {
      this.address = addressData;
      console.log(placeResultData);
      console.log(addressData);
      // Construct address from addressData
      this.address.address = addressData.street_number
        ? `${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`
        : `${addressData.route}, ${addressData.locality}, ${addressData.country}`;

      // Include place ID and establishment name from placeResultData
      this.address.placeId = placeResultData.place_id;
      this.address.establishmentName = placeResultData.name;

      this.deliveryAddress = this.address.address;
      this.$refs.address.update(this.deliveryAddress);
    },
    getStarted() {
      if (!this.$store.getters.getToken) {
        this.toggleshowPopup();
      } else {
        this.$router.push({ name: "Resturants" });
      }
    },
    toggleshowPopup() {
      this.showPopup = !this.showPopup;
    },
    pushResturant(item, timebelt) {
      let publicUrl = this.$store.getters.getOrderStoreName;
      let checkCart = this.$store.getters.getCart.cartItems;
      if (!publicUrl) {
        this.$router.push({
          name: "ResturantsPage",
          params: { publicUrl: this.convertToUrl(this.restaurant.publicURL) },
        });
      } else if (publicUrl == this.restaurant.publicURL) {
        this.$router.push({
          name: "ResturantsPage",
          params: { publicUrl: this.convertToUrl(this.restaurant.publicURL) },
        });
      } else if (checkCart.length < 1) {
        this.$router.push({
          name: "ResturantsPage",
          params: { publicUrl: this.convertToUrl(this.restaurant.publicURL) },
        });
      } else {
        this.error = true;
        this.itemTopush = this.restaurant;
      }
    },
    sortTimebelt(timebelts) {
      timebelts.sort(function (a, b) {
        return a.id - b.id;
      });
      return timebelts;
    },
    convertToUrl(resturantName) {
      console.log(resturantName);
      return resturantName
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    async getDefaultRes() {
      if (window.location.hostname == "noshtrybe.com") {
        this.restaurant.publicURL = process.env.VUE_APP_DEFAULT_STORE;
      }
    },
  },
  computed: {
    getError() {
      return this.$store.state.errAddress;
    },
  },
  mounted() {
    this.deliveryAddress = this.$store.getters.getAddress.address;
    this.$refs.address.update(this.deliveryAddress);
    this.getDefaultRes();
  },
};
</script>
